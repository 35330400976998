<template>
  <div class="ip-container">
    <div class="btn">
      <el-button type="primary" @click="dialogNewFormVsible = true"
        >添加IP</el-button
      >
      <div class="search">
        <el-input v-model="searchObj.IP" clearable placeholder="IP"></el-input>
        <el-input
          v-model="searchObj.Keyword"
          clearable
          placeholder="机构名称"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="getIPList"
          >查询</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%" height="600px">
      <!-- <el-table-column prop="id" label="ID" width="300px"> </el-table-column> -->
      <el-table-column prop="ipAddress" label="IP"> </el-table-column>
      <el-table-column prop="ipType" label="IP格式" :formatter="formatType">
      </el-table-column>
      <el-table-column prop="isValid" label="是否启用">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isValid"
            active-color="#13ce66"
            inactive-color="#999"
            @change="onSwitchChange(scope.row, scope.row.isValid)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="orgName" label="机构名称"></el-table-column>
      <el-table-column prop="expireTime" label="过期时间"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row)"
            >编辑</el-button
          >
          <!-- <el-button type="text" size="small" @click="onDelete(scope.row.id)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="PageIndex"
        :page-sizes="[10]"
        :page-size="PageSize"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="Totals"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹出层 -->
    <el-dialog title="编辑" :visible.sync="dialogEditFormVsible">
      <el-form :model="formData">
        <!-- <el-form-item label="id" :label-width="formLabelWidth">
          <el-input
            v-model="formData.id"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item> -->
        <el-form-item label="ip" :label-width="formLabelWidth">
          <el-input v-model="formData.ipAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="ip格式" :label-width="formLabelWidth">
          <el-select v-model="formData.ipType" placeholder="请选择IP格式">
            <el-option
              v-for="item in ipFormatData"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构名称" :label-width="formLabelWidth">
          <el-select
            v-model="formData.orgCode"
            placeholder="请选择机构"
            filterable
            clearable
            @change="onSelectChange"
          >
            <el-option
              v-for="item in OrganistationList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="oncancel">取 消</el-button>
        <el-button type="primary" @click="onconfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新建ip -->
    <el-dialog title="新建" :visible.sync="dialogNewFormVsible">
      <el-form :model="newFormData" :rules="newFormRule" ref="newIp">
        <el-form-item label="ip" :label-width="formLabelWidth" prop="ipAddress">
          <el-input
            v-model="newFormData.ipAddress"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="ip格式"
          :label-width="formLabelWidth"
          prop="ipType"
        >
          <el-radio-group v-model="newFormData.ipType">
            <el-radio :label="1">ipv4</el-radio>
            <el-radio :label="2">ipv6</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否启用"
          :label-width="formLabelWidth"
          prop="isValid"
        >
          <el-radio-group v-model="newFormData.isValid">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="过期时间" prop="expireTime">
          <el-date-picker
            v-model="newFormData.expireTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="机构名称"
          :label-width="formLabelWidth"
          prop="orgCode"
        >
          <el-select
            v-model="newFormData.orgCode"
            placeholder="请选择机构"
            filterable
            clearable
            @change="onNewSelectChange"
          >
            <el-option
              v-for="item in OrganistationList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="oncancel">取 消</el-button>
        <el-button type="primary" @click="onNewConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validIpAddress } from "@/utils/validate.js";
export default {
  name: "IPManage",
  data() {
    const validatorIpAddress = (rule, value, callback) => {
      console.log("xx");
      validIpAddress(this.newFormData.ipType, value)
        ? callback()
        : callback(new Error("格式错误"));
    };
    return {
      pickerOptions: {
        shortcuts: [
          // {
          //   text: "今天",
          //   onClick(picker) {
          //     picker.$emit("pick", new Date());
          //   },
          // },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 搜索内容
      searchObj: {
        IP: "",
        Keyword: "",
      },
      formLabelWidth: "80px",
      dialogEditFormVsible: false,
      dialogNewFormVsible: false,
      PageSize: 10,
      PageIndex: 1,
      Totals: 0,
      tableData: [], // 表格数据
      formData: {}, // 编辑表单数据
      // 新建表单数据
      newFormData: {
        isValid: true, // 默认启用
        ipType: 1, // 默认ipv4
        ipAddress: "",
        orgCode: "",
        orgName: "",
        expireTime: "",
      },
      newFormRule: {
        ipAddress: [
          { required: true, message: "请输入ip地址", trigger: "blur" },
          { validator: validatorIpAddress, trigger: "blur" },
        ],
        ipType: [{ required: true, message: "不能为空", trigger: "blur" }],
        isValid: [{ required: true, message: "不能为空", trigger: "blur" }],
        orgCode: [{ required: true, message: "不能为空", trigger: "blur" }],
        orgName: [{ required: true, message: "不能为空", trigger: "blur" }],
        expireTime: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      ipFormatData: [
        { value: 1, label: "ipv4" },
        { value: 2, label: "ipv6" },
      ],
    };
  },
  created() {
    this.getIPList();
    this.GetOrganistationList();
  },
  computed: {
    ...mapState(["OrganistationList"]),
  },
  methods: {
    // 新建IP确认
    onNewConfirm() {
      this.$refs.newIp.validate((valid) => {
        if (valid) {
          this.$request({
            method: "POST",
            url: "/api/userrole/valid-ip",
            data: this.newFormData,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getIPList();
              this.oncancel();
            })
            .catch((e) => {
              this.$message.error("操作失败");
            })
            .finally(() => {
              this.$refs.newIp.resetFields();
            });
        }
      });
    },
    // 新建IP 机构选择
    onNewSelectChange(e) {
      this.newFormData.orgName = this.OrganistationList.filter(
        (v) => v.code === e
      )[0].name;
    },
    // 取消
    oncancel() {
      this.dialogEditFormVsible = false;
      this.dialogNewFormVsible = false;
      this.formData = {};
      this.$refs.newIp.resetFields();
    },
    // 编辑 机构选择
    onSelectChange(e) {
      this.formData.orgName = this.OrganistationList.filter(
        (v) => v.code === e
      )[0].name;
    },
    // 编辑 确认
    onconfirm() {
      this.$request({
        method: "PUT",
        url: "/api/userrole/valid-ip/" + this.formData.id,
        data: this.formData,
      }).then(async () => {
        await this.getIPList();
        this.oncancel();
      });
    },
    //请求机构列表
    GetOrganistationList() {
      if (this.OrganistationList.length > 0) return;
      this.$request({
        url: "/api/userrole/organistation",
        method: "GET",
        params: {
          PageSize: this.PageSize,
          PageIndex: 1,
          IsNoPage: true,
          sorting: "Sort",
        },
      })
        .then((res) => {
          this.$store.commit("GetOrganistationList", res.items);
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
    // 删除
    onDelete(id) {
      this.$request({
        method: "DELETE",
        url: "/api/userrole/valid-ip/" + id,
      }).then(async () => {
        await this.getIPList();
      });
    },
    // 编辑
    onEdit(e) {
      // 深拷贝数据回写
      this.formData = JSON.parse(JSON.stringify(e));
      // 打开dialog
      this.dialogEditFormVsible = true;
    },
    // 页码改变
    handleCurrentChange(index) {
      this.PageIndex = index;
      this.getIPList();
    },
    // 格式化数据
    formatType(e) {
      if (e.ipType === 1) {
        return "ipv4";
      } else if (e.ipType === 2) {
        return "ipv6";
      }
    },
    // swith 开关状态改变
    async onSwitchChange(row, status) {
      console.log(row);
      console.log(status, "status");
      if (status) {
        this.$request({
          method: "PUT",
          url: "/api/userrole/valid-ip/" + row.id,
          data: row,
        }).then(async () => {
          await this.getIPList();
        });
      } else {
        await this.onDelete(row.id);
      }
    },
    // 获取表格数据
    getIPList() {
      this.$request({
        method: "GET",
        url: "/api/userrole/valid-ip",
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          ...this.searchObj,
        },
      }).then((res) => {
        this.Totals = res.totalCount;
        this.tableData = res.items;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ip-container {
  // height: 525px;
  position: relative;
  .pagination {
    position: absolute;
    bottom: -35px;
  }

  .btn {
    margin-bottom: 5px;
    display: flex;
    .search {
      margin-left: 15px;
      display: flex;
    }
  }
}
</style>